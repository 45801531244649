.header {
  text-align: center;
}

.toast {
  margin: 0 auto;
}

.gloss {
  border-left-color: #ffe240;
  border-left-style: solid;
  border-left-width: 8px;
  padding-left: 5px;
}

.syntactical { /* Syntactical and Lexical Forms*/
  border-left-color: #c9f140;
  border-left-style: solid;
  border-left-width: 8px;
  padding-left: 5px;
}
.semantic {
  border-left-color: #92ff3f;
  border-left-style: solid;
  border-left-width: 8px;
  padding-left: 5px;
}

.logical {
  border-left-color: #ff4d2d;
  border-left-style: solid;
  border-left-width: 8px;
  padding-left: 5px;
}

.generation {
  border-left-color: #4a9bff;
  border-left-style: solid;
  border-left-width: 8px;
  padding-left: 5px;
}

.tts {
  border-left-color: #8f3fff;
  border-left-style: solid;
  border-left-width: 8px;
  padding-left: 5px;
}

.du {
  margin-top: 60px;
}
.du-nav {
  margin-top: 60px;
  z-index: 1010 !important;
}

.dropdown-menu {
  overflow-y: auto;
  max-height: 80vh;
}
